@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';
@import 'scss/components/cards';

.card {
  @include card;
  @include fadeIn;

  @include from-phone() {
    &:hover {
      background: var(--white);
    }

    &.isSliderItem:hover {
      filter: drop-shadow(var(--shadow-top-8));
      -webkit-filter: drop-shadow(var(--shadow-top-8));
      -moz-filter: drop-shadow(var(--shadow-top-8));
      transform: translateY(calc(var(--space-1) * -1));
      -webkit-transform: translateY(calc(var(--space-1) * -1));
      -moz-transform: translateY(calc(var(--space-1) * -1));
      border-radius: var(--border-radius-md);
    }
  }

  &.twoRowSlider {
    margin-bottom: var(--space-3);
  }

  /*#region loading state */
  &.loading {
    pointer-events: none;

    .productImage,
    .countryName,
    .description {
      @include regionLoading;
    }
  }
  /*#endregion */
}

.collectionImage {
  @include cardImage;
}

.collectionInfo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
  border-radius: var(--border-radius-md);
  padding: var(--space-8) var(--space-2) 0;
  text-align: center;

  @include until-phone {
    padding-top: var(--space-6) !important;
  }

  .headerContainer {
    filter: drop-shadow(0px 2px 48px var(--primary-variant-2));
    -webkit-filter: drop-shadow(0px 2px 48px var(--primary-variant-2));
    -moz-filter: drop-shadow(0px 2px 48px var(--primary-variant-2));

    .header {
      @include header-3;
      color: var(--text-white);
      text-shadow: var(--shadow-top-8);
    }

    .subHeader {
      @include header-6;
      color: var(--text-white);
      margin-bottom: var(--space-2);
      text-shadow: var(--shadow-top-8);

      @include until-phone {
        margin-bottom: var(--space-1);
      }
    }
  }

  .btnContainer {
    position: absolute;
    bottom: var(--space-4);
    left: 0;
    right: 0;
  }
}
