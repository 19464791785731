@import 'scss/mixins';
@import 'scss/typography';

.productCard {
  border: 1px solid var(--p-gray-color-5);
  border-radius: var(--border-radius-lg);
  background-color: var(--white);
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    .image {
      transform-origin: center;
      transform: scale(1.1);
      transition: all 0.25s ease-out;
    }
  }

  &Link {
    height: 100%;
    width: 100%;
    display: inline-block;
    position: relative;
  }

  &.pinned {
    border-color: var(--secondary-opaque-60);
    background-color: var(--info-light-opaque-50);

    .callout {
      background-color: var(--info);
      color: var(--white);
    }
  }

  &.loading {
    .imageContainer {
      @include placeholder;
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: var(--space-2);

      .destinationName {
        @include placeholder;
        border-radius: var(--border-radius-32);
        height: 12px;
        width: 30%;
      }

      .productName {
        @include placeholder;
        border-radius: var(--border-radius-32);
        height: 20px;
      }

      .footer {
        margin-top: 14px;
        .label {
          @include placeholder;
          border-radius: var(--border-radius-32);
          height: 20px;
        }
      }
    }

    &.small {
      .footer {
        margin-top: 22px;
      }
    }
  }

  &.small,
  &.xsmall {
    border-radius: var(--border-radius-md);

    .rating {
      padding: var(--space-1) var(--space-3);
    }

    .callout {
      padding: var(--space-1) var(--space-2);
      top: 10px;
      left: 10px;
    }

    .wishlistBtn {
      top: var(--space-2);
      right: var(--space-2);

      > button > svg {
        @include size(24px);
      }
    }

    .body {
      padding: var(--space-3);

      .ranking {
        @include label-md;
        font-weight: var(--font-weight-extra-bold);
      }

      .productName {
        @include label-sm;
      }

      .footer {
        .priceAndLabel {
          align-items: flex-start;
          flex-direction: column-reverse;
        }

        .priceValue {
          &.smallText {
            @include label-sm-bold;

            > span {
              margin-left: var(--space-1);
            }
          }
        }

        .label {
          max-width: 100%;
        }
      }
    }
  }

  &.xsmall {
    .imageContainer {
      aspect-ratio: 3 / 2;
    }

    .body {
      .footer {
        .priceValue {
          @include label-sm-bold;
        }

        .label {
          @include text-xs;
        }
      }
    }
  }

  &.small {
    .imageContainer {
      aspect-ratio: 1 / 1;
    }

    .body {
      .productName {
        @include overflowedText(3);
      }
    }
  }

  &.large {
    .imageContainer {
      aspect-ratio: 16 / 9;
    }
  }

  .image {
    object-fit: cover;
    overflow: hidden;
    transition: all 0.2s ease-out;

    &Container {
      position: relative;
      overflow: hidden;
      aspect-ratio: 3 / 2;
    }
  }

  .callout {
    @include text-sm;
    @include overflowedText(1);
    max-width: calc(
      100% - 62px
    ); // 10px from left position + 8px from spacing between wishlist + 44px wishlist button from design
    background-color: var(--yellow-interaction-standard);
    box-shadow: var(--shadow-bottom-2);
    padding: 6px 10px;
    border-radius: var(--border-radius-smd);
    position: absolute;
    top: 12px;
    left: 12px;
    color: black;
    font-weight: var(--font-weight-bold);
  }

  .rating {
    @include label-sm-bold;
    padding: var(--space-1) var(--space-4);
    border-top-right-radius: var(--border-radius-md);
    display: flex;
    align-items: center;
    gap: var(--space-1);
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(16px);
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;

    &Icon {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 0.5px;
      @include size(12px);

      svg {
        @include size(10px);
        fill: var(--primary);

        path {
          stroke: var(--primary);
        }
      }
    }
  }

  .reviewCount {
    @include text-sm;
    font-weight: normal;
    color: var(--white);
  }

  .wishlistBtn {
    position: absolute;
    top: var(--space-3);
    right: var(--space-3);
    padding: 2px;
    height: fit-content;
    display: flex;
    aspect-ratio: 1 / 1;

    > button > svg {
      @include size(28px);
    }
  }

  .body {
    padding: var(--space-4);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .bodyHeader {
      display: flex;
      flex-direction: column;
    }

    .ranking {
      @include label-lg-bold;
      display: flex;
      align-items: center;
      gap: 2px;
      margin-bottom: 2px;
    }

    .destinationName {
      @include text-xs;
      color: var(--text-color-grey-1);
      display: inline-block;
      margin-bottom: 2px;
    }

    .productName {
      @include label-md-bold;
      @include overflowedText(2);
      color: var(--text-color-grey-1);
      word-break: break-word;
    }

    .footer {
      margin-top: var(--space-2);

      .priceAndLabel {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: var(--space-2);
      }

      .fromPriceValue {
        @include text-xs;
        color: var(--text-color-grey-1);
        white-space: nowrap;
        margin-bottom: 2px;
      }

      .priceValue {
        @include label-md-bold;
        flex: 1;
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: var(--text-color-grey-1);
      }

      .label {
        @include overflowedText(1);
        @include text-sm;
        flex-shrink: 1;
        flex-grow: 0;
        background-color: var(--info-light);
        color: var(--info-text);
        padding: var(--space-1) var(--space-2);
        border-radius: var(--border-radius-sm);
      }
    }
  }
}
