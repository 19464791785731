@import 'scss/typography';
@import 'scss/media-queries';

.ongoingAppBanner {
  &Container {
    height: 100%;
    padding-top: var(--space-2);

    @include until-phone {
      padding-top: 0;
    }
  }

  border-radius: var(--border-radius-lg);
  padding: var(--space-4) var(--space-5);
  position: relative;
  background-color: var(--primary-variant-2);
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: transform 0.3s;
  aspect-ratio: 16 / 9;

  &:hover {
    transform: translateY(calc(var(--space-2) * -1));
  }

  .appMockup {
    position: absolute;
    bottom: 0;
    right: var(--space-5);
    width: 84px;
    min-height: 1px; // when height is 0px, sometimes image lazy load in view state does not update correctly, added this helps in making sure the state updates correctly
    transform: translateY(40%);
    z-index: 1;
  }

  .illustration {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(40%, 50%) rotate(-10deg);

    path {
      fill: var(--primary);
    }
  }

  .content {
    color: var(--white);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    .title {
      @include label-lg;
      font-weight: var(--font-weight-extra-bold);

      span {
        color: var(--primary);
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      gap: var(--space-1);
      position: relative;
      z-index: 1;

      button:hover:not(:disabled):not(:active) {
        transform: translateY(0);
      }

      a {
        display: flex;
      }
    }
  }

  @include until-phone {
    padding: var(--space-5);

    &:hover {
      transform: translateY(0);
    }

    .appMockup {
      transform: translateY(45%);
    }

    .content {
      align-items: flex-start;
      gap: var(--space-3);
    }
  }
}
